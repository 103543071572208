/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-classes-per-file */
import { isString, omit } from 'lodash-es';
import m from 'moment';

import { PaymentCardsUtils } from '@bp/shared/domains/payment-cards';
import { CryptoCurrency, CryptoCurrencyCode, FiatCurrency, FiatCurrencyCode } from '@bp/shared/models/currencies';
import { Dictionary } from '@bp/shared/typings';
import { isURL } from '@bp/shared/utilities/core';
import { normalizeHtml } from '@bp/shared/models/metadata';

import { PaymentOptionType, PspPaymentOptionType } from '@bp/frontend/models/business';
import { TelemetryService } from '@bp/frontend/services/telemetry';

import { IBank } from './bank';

export type TransactionStatus = 'approved_on_hold' | 'approved' | 'authorized' | 'cancelled' | 'capture_in_progress' | 'declined_due_to_invalid_credit_card' | 'declined_due_to3_d_auth_failure' | 'declined_try_again' | 'declined' | 'error' | 'in_process' | 'pending' | 'three_ds_not_authenticated' | 'voided';

export interface ITransactionBase {
	id: string;
	trx_id: string;
	transaction_id: string;
	payment_method?: PaymentOptionType | PspPaymentOptionType;
	amount?: number;
	processing_date?: string;
	status?: TransactionStatus;
	details?: Record<string, string>;
	installment_details?: Record<string, string>;
	is_cancelled?: boolean;
}

export interface IApiTransaction extends ITransactionBase {
	html?: string;
	currency?: FiatCurrencyCode;
	wire_transfer_details?: Dictionary<string>;
}

export interface IApiCryptoTransaction extends IApiTransaction {
	crypto: {
		address?: string;
		amount: number;
		currency?: CryptoCurrencyCode;
		expires_at?: number;
		qr_code?: string;
	};
}

export interface IApmEtfTransaction extends IApiTransaction {

	banks: IBank[];

}

export class TransactionInfo implements ITransactionBase {

	id!: string;

	trx_id!: string;

	/**
	 * psp transaction id
	 */
	transaction_id!: string;

	payment_method_instance_id!: string; // paywith

	amount!: number;

	processing_date?: string;

	processingDateDisplay!: string;

	status?: TransactionStatus;

	is_cancelled?: boolean;

	currency?: FiatCurrency | null;

	details?: Record<string, string>;

	installment_details?: Record<string, string>;

	html?: string;

	payment_method?: PaymentOptionType;

	masked_credit_card_number?: string;

	formatted_masked_credit_card_number?: string;

	get isApproved(): boolean {
		return !!this.status?.startsWith('approved') || this.isAuthorized;
	}

	get isApprovedOnHold(): boolean {
		return this.status === 'approved_on_hold';
	}

	get isAuthorized(): boolean {
		return this.status === 'authorized' || this.status === 'capture_in_progress';
	}

	get isDeclined(): boolean {
		return (!!this.status?.startsWith('declined') || this.status === 'three_ds_not_authenticated') && !this.isTryAgain;
	}

	get isTryAgain(): boolean {
		return this.status === 'declined_try_again';
	}

	get isDeclinedDueToInvalidCard(): boolean {
		return this.status === 'declined_due_to_invalid_credit_card';
	}

	get isError(): boolean {
		return this.status === 'error';
	}

	get isCreditCardTrxInProcess(): boolean {
		return this.status === 'in_process';
	}

	get isPending(): boolean {
		return this.status === 'pending';
	}

	get isPendingOrInProcess(): boolean {
		return this.isPending || this.isCreditCardTrxInProcess;
	}

	get isCancelled(): boolean {
		return this.status === 'cancelled';
	}

	summary: {
		amount: number;
		currency?: FiatCurrencyCode;
		processingDate?: string;
		maskedCreditCardNumber?: string;
	};

	constructor(dto: IApiTransaction) {
		Object.assign(this, dto);

		if (this.is_cancelled)
			this.status = 'cancelled';

		if (dto.payment_method)
			this.payment_method = PaymentOptionType.parseStrict(dto.payment_method);

		this.currency = isString(dto.currency)
			? new FiatCurrency(dto.currency)
			: dto.currency;

		if (this.masked_credit_card_number) {
			this.formatted_masked_credit_card_number = PaymentCardsUtils.formatMaskedCardNumber(
				this.masked_credit_card_number,
			);
		}

		if (this.processing_date) {
			const momentProcessingDate = m(this.processing_date);

			this.processingDateDisplay = momentProcessingDate.isValid()
				? momentProcessingDate.format('lll')
				: this.processing_date;
		}

		TelemetryService.log('Transaction', omit(dto, 'html'));

		this.summary = {
			amount: this.amount,
			currency: this.currency?.code,
			processingDate: this.processing_date,
			maskedCreditCardNumber: this.masked_credit_card_number,
		};
	}
}

export class Transaction extends TransactionInfo {

	is_decoded?: boolean;

	open_in_new_window!: boolean;

	wire_transfer_details?: Dictionary<string>;

	constructor(data: IApiTransaction) {
		super(data);

		this.html &&= isURL(this.html)
			? (this.__isUrlEncoded(this.html) ? decodeURIComponent(this.html) : this.html)
			: normalizeHtml(this.html);
	}

	private __isUrlEncoded(url: string): boolean {
		return !url.includes('/');
	}
}

export class CryptoTransaction extends TransactionInfo {

	/**
	 * An url to the crypto store
	 */
	override html!: string;

	crypto!: {
		address?: string;
		amount: number;
		currency?: CryptoCurrency;
		expires_at?: m.Moment;
		// base64
		qr_code?: string;

		/**
		 * This parameter helps to identify the right recipient. More info https://wirexapp.com/help/article/what-is-a-stellar-memo-0050.
		 * Done for a few cryptos
		 */
		memo?: string;
	};

	constructor(dto: IApiCryptoTransaction) {
		super(dto);

		if (dto.crypto.currency)
			this.crypto.currency = new CryptoCurrency(dto.crypto.currency);

		if (dto.crypto.expires_at)
			this.crypto.expires_at = m.unix(dto.crypto.expires_at);
	}
}

export class ApmEtfTransaction extends TransactionInfo {

	banks!: IBank[];

	constructor(dto: IApmEtfTransaction) {
		super(dto);
	}

}
