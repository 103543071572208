
import { IEnvironmentConfig } from '@bp/shared/typings';

import { LocalBackendState } from '@bp/frontend/services/persistent-state-keepers';
import { TelemetryService, ReportEventSource, SOURCE_TAG } from '@bp/frontend/services/telemetry';

import { HAS_SELF_SUBMIT_FORM_KEY } from '../models/constants';

import { isEmbedded } from './is-embedded';

export function patchBackendGeneratedPspHtml(html: string, environmentConfig: IEnvironmentConfig): string {
	const $document = new DOMParser().parseFromString(html, 'text/html').documentElement;
	const $head = $document.querySelector('head')!;

	if (!html.includes('Sentry'))
		addSentryMonitoring($head, environmentConfig);

	askCheckoutToShowProcessingStubOnRedirect($head);

	whenStandaloneAndHtmlHasPostFormSetTopTargetContext($document);

	return $document.outerHTML;
}

function addSentryMonitoring($head: HTMLHeadElement, environment: IEnvironmentConfig): void {
	if (LocalBackendState.isActive)
		return;

	const sentryHtml = /* html*/ `
		<script src="${ environment.isDeployed ? 'sentry/bundle.min.js' : 'https://browser.sentry-cdn.com/8.26.0/bundle.min.js' }"></script>
		<script>
		    window.Sentry && Sentry.onLoad(() => {
		        Sentry.init({
					dsn: "https://b12f4095854049b58fc6eb80659c5ff6@o205478.ingest.us.sentry.io/4505442578923520",
					tunnel: ${ environment.isDeployed ? '"/sentry/tunnel"' : 'undefined' },
		            release: "${ environment.appVersion.releaseTitle }",
		            environment: "${ environment.name }",
					normalizeDepth: 0,
					denyUrls: [
						/quantum-zalora-test/u,
					]
		        });

		        Sentry.setTags({
					...${ JSON.stringify(TelemetryService.getTags()) },
					${ SOURCE_TAG }: "${ ReportEventSource.PatchedByFrontendDotnetGeneratedHtml }",
				});
		    });
		</script>
	`;

	$head.insertAdjacentHTML(
		'afterbegin',
		sentryHtml,
	);
}

function whenStandaloneAndHtmlHasPostFormSetTopTargetContext($document: HTMLElement): void {
	if (isEmbedded())
		return;

	const $form = findTheOnlyFormTargetingSelfContext($document);

	if ($form) {
		$form.target = '_top';

		$form.dataset[HAS_SELF_SUBMIT_FORM_KEY] = HAS_SELF_SUBMIT_FORM_KEY;
	}
}

function findTheOnlyFormTargetingSelfContext($container: HTMLElement): HTMLFormElement | null {
	const $forms = [ ...$container.querySelectorAll<HTMLFormElement>('form[method=post]:not([target])') ]
		.filter($form => !$form.querySelector('[formtarget]') && !$form.querySelector('[type=submit]'));

	if ($forms.length > 1) {
		TelemetryService.captureMessage(
			'More than one self target form found in the transaction html; skipping patching',
		);

		return null;
	}

	return $forms[0] ?? null;
}

function askCheckoutToShowProcessingStubOnRedirect($head: HTMLHeadElement): void {
	const notifyScript = /* html */ `
		<script>
		    addEventListener(
		        'beforeunload',
		        () => parent.postMessage({ event: '[bp]:show-processing-stub' }, '*')
		    );
		    addEventListener(
		        'unload',
		         () =>  parent.postMessage({ event: '[bp]:hide-processing-stub' }, '*')
		    );
		</script>
	`;

	$head.insertAdjacentHTML('afterbegin', notifyScript);
}
